import * as React from 'react'
import { GlobalContext } from '../../Context/GlobalContext/CreateContext'

export interface ProductsInterface {
    id: number
    img: string
    title: string
    price: number
    unit_price: number,
    note: string
}
interface Props {
    product: ProductsInterface
    // setCartProducts: React.Dispatch<React.SetStateAction<ProductsInterface[]>>
}

const CartInput = ({ product }: Props) => {
    const [currentValue, setCurrentValue] = React.useState(1)
    const { cart, maximumQuantity, minimumQuantity } = React.useContext(GlobalContext)

    console.log(cart)

    const increment = () => {
        setCurrentValue(currentValue + 1)
        return maximumQuantity(product.id, (currentValue + 1))
    }
    const decrement = () => {
        if (currentValue > 1) {
            setCurrentValue(currentValue - 1)
            return minimumQuantity(product.id, (currentValue - 1))
        }
    }
    return (
        <div className="input-group">
            <button
                style={{ minWidth: "2.5rem" }}
                className="btn btn-decrement btn-outline-secondary btn-minus"
                onClick={decrement}
                type="button"
            >
                <strong>−</strong>
            </button>
            <input
                type="text"
                inputMode="decimal"
                min="0"
                step="1"
                style={{ textAlign: "center" }}
                className="form-control numspin"
                placeholder=""
                value={currentValue}
                readOnly={true}
            />
            <button
                style={{ minWidth: "2.5rem" }}
                className="btn btn-increment btn-outline-secondary btn-plus"
                type="button"
                onClick={increment}
            >
                <strong>+</strong>
            </button>
        </div>
    )
}

export default CartInput
