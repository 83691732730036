import { GlobalContext } from '../../Context/GlobalContext/CreateContext'
import { Link } from 'gatsby'
import * as React from 'react'
import X16 from "../../Assets/Svg-Icons/x-16.svg"
import CartInput, { ProductsInterface } from "./CartInput"
import { productsData } from '../../Data/productsData'

const Products = () => {
    const { cart } = React.useContext(GlobalContext)


    return (
        <div style={{ overflowY: 'scroll', maxHeight: '30rem' }}>
            <table >
                <thead>
                    <tr>
                        <th></th>
                        <th colSpan={2}>PRODUCT</th>
                        <th>PRICE</th>
                        <th>QTY</th>
                        <th>UNIT PRICE</th>
                    </tr>
                </thead>
                <tbody>
                    {cart?.products?.map((product) => {
                        return (
                            <tr key={product.id}>
                                <td>
                                    <Link to="/" className="btn-delete">
                                        <X16 className="svg" />
                                    </Link>
                                </td>
                                <td className="product-image">
                                    <img src={product.img} alt="sample" />
                                </td>
                                <td>
                                    <div className="product-title">
                                        {product.title}
                                    </div>
                                    <div className="note1">
                                        {product.note}
                                    </div>
                                </td>
                                <td>
                                    <div className="price">{product.price}</div>
                                </td>
                                <td>
                                    <div className="qty">
                                        <CartInput
                                            product={product} />
                                    </div>
                                </td>
                                <td>
                                    <div className="unit-price">{product.unit_price}</div>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default Products
